import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { NftPurchaseContract,chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";

const PurchaseNFTwithEth = () => {
  // const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);
  const purchaseNFTwithEth = useCallback(
    async ( postApiRes,currentRoundd,nftPriceArry,price,nftPriceArry1,balance,indexx,checkBoxx,insurancePer) => {
      if(checkBoxx){

        let percecnt=parseFloat(nftPriceArry1)*insurancePer
        let value = parseFloat(nftPriceArry1)+percecnt
        nftPriceArry1=parseFloat(value)+parseFloat(0.0001)
        nftPriceArry1=nftPriceArry1.toFixed(6)
        nftPriceArry1=web3.utils.toWei(nftPriceArry1.toString(), "ether");

      }else
      {
        nftPriceArry1=parseFloat(nftPriceArry1)+parseFloat(0.0001)
        nftPriceArry1=nftPriceArry1.toFixed(6)
        nftPriceArry1=web3.utils.toWei(nftPriceArry1.toString(), "ether");
      }
    // let amount11 = parseFloat(amount).toFixed(6)
    //   let amount1 = Math.round((amount11) * 1e12) / 1e12;
    //   let nd = web3.utils.toWei(amount1.toString(), "ether");
    //   let minvalue=min*0.99
    //   minvalue= parseFloat(minvalue).toFixed(6)
    //   minvalue=Math.round((minvalue) * 1e12) / 1e12;
    //   minvalue= web3.utils.toWei(minvalue.toString(), "ether");
 
      // let tuple = {
      // code:postApiRes.code,
      // GodAgentPercentage:postApiRes.gotAgentPercentage,
      // MegaAgentPercentage:postApiRes.megaAgentPercentage,
      // SuperAgentPercentage:postApiRes.superAgentPercentage,
      // AgentPercentage:postApiRes.agentPercentage,
      // GodAgentAddress:postApiRes.gotAgentAddress,
      // MegaAgentAddress:postApiRes.megaAgentAddress,
      // SuperAgentAddress:postApiRes.superAgentAddress,
      // AgentAddress:postApiRes.agentAddress,
      // let b = 0
      // nftPriceArry1?.map((e)=>{
      // b=e+b
      // })
      // console.log(nftPriceArry1,'nftPriceArry1');
     
      // nftPriceArry1=(nftPriceArry1*ethPricevalue)/price
  
      // console.log(nftPriceArry1);
      // b=b*10**18

      // };
      // console.log(postApiRes?.code,id,postApiRes.deadline,minvalue, postApiRes?.v, postApiRes?.r, postApiRes?.s,'nooor');
      console.log(postApiRes?.code,currentRoundd,postApiRes.deadline,nftPriceArry,indexx,postApiRes?.agentsAddresses,postApiRes?.agentsPercentages,postApiRes?.v, postApiRes?.r, postApiRes?.s,checkBoxx);
      let gas
      try {
        // let gasPrice = await web3.eth.getGasPrice()
        // gasPrice = parseInt(gasPrice) + 3000000000
        //  gas = await contract.methods
        //   .purchaseNFTWithETH(postApiRes?.code,currentRoundd,nftPriceArry,postApiRes.deadline ,indexx, postApiRes?.v, postApiRes?.r, postApiRes?.s,checkBoxx)
        //   .estimateGas({
        //     value: nftPriceArry1,
        //     from: account,
        //     gasPrice
        //   });
        const details = await contract.methods
          .purchaseNFTWithETH(postApiRes?.code,currentRoundd,nftPriceArry,postApiRes.deadline,indexx,postApiRes?.agentsAddresses,postApiRes?.agentsPercentages,postApiRes?.v, postApiRes?.r, postApiRes?.s,checkBoxx)
          .send({
            value:nftPriceArry1,
            from: account,
            // gas: gas,
            // gasPrice
          });
        return details;
      } catch (error) {
        // let gasPrice1=await web3.eth.getGasPrice()
        // let gasPrice = parseInt(gasPrice1) + 3000000000
        // gas=gas*gasPrice
        // let nd = web3.utils.fromWei(gas.toString(), "ether");
        // console.log(gas,nd,balance );
        // if(nd>balance){
        //    toast.error("Insufficient ETH for Transaction")
        // }else{
          throw error;
        // }
      }
    },
    [contract, account,web3]
  );
  return { purchaseNFTwithEth: purchaseNFTwithEth };
};
export default PurchaseNFTwithEth;
